<template>
  <div v-show="!$parent.isStoreAdmin" cols="12">
    <div class="mb-4"><h3>Edit permissions</h3></div>
    <v-row no-gutters>
      <v-col cols="12" sm="6" offset-2>
        <h4 class="ml-10">General & Online Store</h4>
        <v-checkbox
          v-model="$parent.permissions"
          hide-details=""
          :class="isChildPermission(i) ? 'ml-5' : ''"
          v-for="i in $parent.listPermissions"
          :key="i"
          :value="i"
        >
          <template v-slot:label>
            <span style="text-transform: capitalize;">
              {{ convertName(i) }}
            </span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col class="" cols="12" sm="6">
        <!-- <h4>Online Store</h4> -->
        <!-- <v-checkbox
          hide-details=""
          :label="i.name"
          v-for="i in permissionsStore"
          :key="i.name"
          :value="i.name"
          v-model="i.value"
        >
        </v-checkbox> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    convertName(i) {
      if (i === 'dashboards') {
        i = `dashboards Analytics`;
      }
      return i.replace('_', ' ');
    },
    isChildPermission(i) {
      if (i === 'write_orders' || i === 'export_orders' || i === 'payment_providers') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
