<template>
  <div class="accounts__page" v-if="data">
    <Redirect class="" title="ACCOUNT" route="website.settings.account" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">{{ data.email }}</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters class="py-4">
      <v-col cols="12" sm="4" class="">
        <h3>Account information</h3>
      </v-col>
      <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column ">
        <h3>Staff profile</h3>
        <div class="d-flex mt-3">
          <div>
            <v-avatar size="40" color="red">
              <img :src="imageFake.user" alt="alt" />
            </v-avatar>
          </div>
          <div v-if="user" class=" ml-3">
            <span class="link-add">{{ data.email }}</span>
          </div>
        </div>
        <h3>Notifications</h3>
        <v-checkbox hide-details="" label="Allow important notifications to be sent by email"> </v-checkbox>
        <div class="ml-5">
          <small class="input-description"
            >We periodically send out important news about Hiweb to our users via email. We keep the email volume to an
            absolute minimum.</small
          >
        </div>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>
      <v-row no-gutters class="py-4">
        <v-col cols="12" sm="4" class="">
          <h3>Admin access</h3>
        </v-col>
        <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column ">
          <h3>Customize permission</h3>
          <div>Enable or restrict {{ data.email }} ’s access to various parts of this store.</div>
          <v-checkbox :label="`${data.email} has full permission`" v-model="isStoreAdmin"> </v-checkbox>
          <SelectPermission />
        </v-col>
      </v-row>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>
      <v-row no-gutters class="py-4">
        <v-col cols="12" sm="4" class="">
          <h3>Recent login history</h3>
          <p>
            Access from an ISP, Location, or IP address you don't recognize may indicate that the account has been
            compromised and should be reviewed further.
          </p>
        </v-col>
        <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column ">
          <table class="h-table">
            <thead>
              <tr>
                <th>
                  <p>Date</p>
                </th>
                <th>
                  <p>IP</p>
                </th>
                <!-- <th>
                  <p>ISP</p>
                </th> -->
                <th>
                  <p>Location</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in historyLogin" :key="i.createdAt">
                <td>
                  <span>
                    {{ i.createdAt }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ i.ip }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ 'Viet Nam' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <v-btn v-if="isStoreOwner" color="" class="mr-3 mt-5" @click="showModalLogout = true">
            Log out {{ data.email }}</v-btn
          >
        </v-col>
      </v-row>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>
      <v-row no-gutters class="py-4" v-if="isStoreOwner">
        <v-col cols="12" sm="4" class="">
          <h3>Remove staff account</h3>
        </v-col>
        <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column ">
          <h3>Delete staff account</h3>
          <p>Deleted staff accounts will be permanently removed from your store.</p>
          <p>This action cannot be reversed.</p>
          <v-btn color="error" class="mr-3" @click="showModalDeleteAccount = true">Delete staff account</v-btn>
        </v-col>
      </v-row>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>
      <v-col v-if="isStoreOwner" cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Cancel</v-btn>
        <v-btn color="primary" @click="onSubmit">Save</v-btn>
      </v-col>
    </v-row>
    <modal model="showModalLogout" :title="`Log out ${data.email}`" :callback="submitLogoutStaff">
      <template slot="content">
        <small class="input-description"> Are you sure you want to log out {{ data.email }} ? </small>
      </template>
    </modal>
    <modal model="showModalDeleteAccount" :title="`Delete account ${data.email}`" :callback="submitDeleteAccount">
      <template slot="content">
        <small class="input-description"> Are you sure you want to delete account {{ data.email }} ? </small>
      </template>
    </modal>
  </div>
</template>
<script>
import Redirect from '@/components/RedirectTo';
import { mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import SelectPermission from './SelectPermission';
import { authService } from '@/apis/permission';
import { imageFake } from '@/const/image';

export default {
  components: {
    Redirect,
    SelectPermission,
  },
  data() {
    return {
      imageFake,
      showModalLogout: false,
      showModalDeleteAccount: false,
      idAccount: null,
      email: '',
      errors: [],
      permissions: [],
      isStoreAdmin: true,
      isStoreOwner: false,
      listPermissions: [],
      isLoading: false,
      historyLogin: [],
      data: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async submitDeleteAccount() {
      try {
        await authService.deleteStaffAccount(this.idAccount);
        this.showModalDeleteAccount = false;
        this.$router.push({ name: 'website.settings.account' });
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      this.$router.push({ name: 'website.settings.account' });
    },
    async submitLogoutStaff() {
      try {
        await authService.logoutAddStaffAccountById(this.idAccount);
        this.showModalLogout = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getPermission() {
      try {
        let res = await authService.getAllPermission();
        this.listPermissions = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        let payload = {
          email: this.email,
          isStoreAdmin: this.isStoreAdmin,
        };
        if (!this.isStoreAdmin) payload.permissions = this.permissions;
        let res = await authService.updatePermissionStoreStaff(this.idAccount, payload);
        // this.permissions = this.data.scopes;
      } catch (error) {
        console.log(error);
      }
    },
    async checkOwnerStore() {
      try {
        let res = await authService.storePermission();
        this.isStoreOwner = res.data.isStoreOwner;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    try {
      this.idAccount = this.$route.params.id;
      this.getPermission();
      this.data = (await authService.getStoreStaffInfoById(this.idAccount)).data;
      let history = await authService.apiHistoryAccountById(this.idAccount);
      this.historyLogin = history.data;
      this.isStoreAdmin = this.data.isStoreAdmin;
      this.permissions = this.data.scopes;
      this.checkOwnerStore();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="scss" scoped>
.h-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.h-table td,
.h-table th {
  border-bottom: 1px solid #ddd;
  padding: 12px;
}

.h-table th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: left;
  color: black;
  // background-color: #dddddd;
  // color: white;
  p {
    margin-bottom: 0px !important;
  }
}
</style>
